<template>
  <div class="privacy-page">
    <van-nav-bar
      title="隐私政策"
      left-arrow
      safe-area-inset-top
      placeholder
      @click-left="onClickLeft"
      :style="{'padding-top': statusBarHeight + 'px'}"
    />
    <div class="privacy-content">
      慧盈数据是一款专业的商业智能数据分析软件，该软件由北京清科慧盈科技有限公司（以下简称“清科慧盈”）提供，为用户实现基于自然语言深度搜索的智能数据分析服务。<br><br>

      本隐私声明描述了清科慧盈的一般隐私惯例，这些惯例适用于我们收集和使用有关我们的客户、业务合作伙伴、供应商和与清科慧盈有业务关系或考虑与之建立业务关系的其他组织以及为其工作个人的个人信息。<br><br>

      在清科慧盈，我们高度重视用户的隐私保护，并致力于负责任地处理这些信息，保证这些信息的安全，在此过程中严格遵守中国及相关国家的法律。此隐私策略适用于清科慧盈通过显示此隐私策略的网站、移动应用、产品和服务收集的信息。<br><br>

      使用清科慧盈提供的服务，即表示您接受此隐私策略所述惯例，并同意清科慧盈按照此隐私策略所述方式使用您的信息。<br><br>

      一、 关于收集信息的声明<br>
      清科慧盈可能向您收集有关您的个人或企业信息，您在注册清科慧盈账号或使用清科慧盈服务时，我们可能收集的个人信息包括：姓名、电子邮件地址、电话号码、IP 地址；公司信息：公司/团队名称、Logo（或商标、企业标识）、公司/团队介绍、公司/团队通讯地址等相关信息，以及您在购买过程中可能会涉及到的帐户信息。<br><br>

      清科慧盈可能会采用多种不同方式来接收和收集您的个人信息。这些方式可能包括：<br><br>

      （1）来自您的信件<br>
      您给我们发送电子邮件或写信时，可能会在邮件中提供您的公司/团队信息、您的姓名、联系详细信息以及其他个人信息。<br><br>

      （2）技术服务<br>
      您在打电话或发电子邮件给我们请求产品方面的技术帮助或索取有关我们产品的信息时，可能向我们提供企业/团队和个人信息。<br><br>

      （3）产品注册<br>
      注册清科慧盈产品时，系统会要求您提供信息，以便我们将您记录为清科慧盈产品的所有者，从而有助于我们为您提供服务和信息。当您浏览清科慧盈网站时，服务器会自动收集您的 IP 地址，此IP地址只被计算机用来向您发送相关的页面 , 帮助您监控非授权登录<br><br>

      （4）第三方登录<br>
      您通过第三方产品（如钉钉）使用清科慧盈时，系统会自动识别出您的企业信息，以便我们记录您为清科慧盈的使用者，从而有助于我们为您提供服务和信息。<br><br>

      （5）购买<br>
      如果您希望通过我们的在线支付系统订购清科慧盈的产品，我们将需要足够的信息才能完成您的订购， 这些信息包括您企业/团队的银行账户信息或个人的信用卡信息。<br><br>

      （6）招聘<br>
      您为应聘清科慧盈工作岗位和/或为此提交简历时，我们将获得您的个人信息，但我们只会将这些信息用于评估您是否有能力成为清科慧盈的员工。<br><br>

      二、 关于收集信息用途的声明<br>
      清科慧盈可能将您的个人信息用于以下用途：<br><br>

      （1）产品注册<br>
      您注册时所提供的信息将用于创建您的清科慧盈账号，并且在您联系清科慧盈用户服务部门时， 这些信息还将协助清科慧盈为您提供支持。清科慧盈还将使用这些信息来通知您有关您可能感兴趣的任何升级、新产品、促销或其他信息。<br><br>

      （2）产品的使用<br>
      清科慧盈可能将产品使用中收集的信息用于：定期验证您对产品的使用权利； 向您发送新产品/新功能升级的通知前，确认您是否正在使用该产品/功能的较早版本或者是否会对您使用清科慧盈有所帮助； 以及，从清科慧盈接收您请求获取的产品内部消息。<br><br>

      （3）产品购买<br>
      如果您通过清科慧盈的在线支付系统购买产品，清科慧盈将使用您的信息来处理付款并将产品购买信息发送给您。<br><br>

      （4）产品功能优化<br>
      我们可能会使用您提供给我们的信息进行产品内部统计和功能分析，从而评估并增强您对清科慧盈产品的体验，包括通过识别您的喜好和购买意向，进行营销以及与运营和开发相关的活动。<br><br>

      如果您希望成为清科慧盈网站的用户，您必须注册并提供相应的信息。当您在清科慧盈网站注册帐户时，清科慧盈网站需要收集您的电子邮箱等 信息。当您浏览清科慧盈网站时，服务器会自动收集您的IP地址，此IP地址只被计算机用来向您发送相关的页面 , 帮助您监控非授权登录。<br><br>

      三、 自动收集的信息<br>
      无论何时，只要您通过清科慧盈进入我们的网站或访问任何在线信息，我们的 Web 服务器就会自动收集和汇总有关您的访问的信息 （以下简称“自动信息”）。自动信息可能包括相关网站的 URL 或域、浏览器类型、操作系统、IP地址、您所访问的页面以及访问日期和时间。<br><br>

      四、 用户文件<br>
      清科慧盈允许您存储、发送、接收、编辑、同步、共享或者以其他方式组织或管理文件和文件夹 （包括这些文件夹中存储的任何信息或数据）（以下统称“用户文件”）。 我们可能获得您在清科慧盈上储存的所有用户文件的大小，因为我们需要了解您对清科慧盈的实际使用情况。<br><br>

      五、 与第三方共享信息<br>
      清科慧盈网站不会在未经合法用户授权时，公开、编辑或透露其信息及保存在清科慧盈网站中的非公开内容，除非在下述情况下部分或全部将被披露：<br><br>

      （1）服务提供商<br>
      我们有时会雇用其他公司代表我们提供服务，比如处理交易、邮件的处理和寄送、提供用户支持、 托管网站或者针对我们的产品或服务进行统计分析。我们有时需要将您企业/团队和个人的信息与其他公司共享， 以便这些公司能够提供适用的服务。我们仅向这些公司提供其向您提供服务所需的信息， 但未授权这些公司将您的信息用于清科慧盈之外的任何其他用途。<br><br>

      （2）法定披露<br>
      根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；如果您出现违反中国有关法律法规的情况，需要向第三方披露；为提供您所要求的软件或服务，而必须和第三方分享您的数据；<br><br>

      （3）其它需要公开、编辑或透露用户信息的情况。<br><br>

      六、 安全<br>
      我们会采取合理的实际及电子手段以及规程保障措施来保护您的企业/团队和个人信息。 虽然通过因特网信息传输数据并非100% 安全，但我们已经采取并将继续采取商业范畴内合理的努力来确保您的个人信息得到保护。<br><br>

      七、 加密<br>
      清科慧盈所有的数据都基于SSL/HTTPs传输，保证数据畅通且安全。<br><br>

      八、 COOKIE<br>
      Cookie是仅限文本的信息字符串，网站会将这些字符串传输到计算机硬盘上浏览器的 Cookie 文件中， 以便网站能够记住您的身份信息以及其他详细信息。Cookie 可能由网站设置或来源自第三方，比如广告商。Cookie 本身不用于或意图用于从用户的计算机读取任何信息 （Cookie 本身的内容除外）。Cookie 只是网站所使用的最初将其放置在您硬盘上的标识符。同一台服务器可以检索到 Cookie 信息的实际内容， 从而标识计算机并进而根据主服务器上存储的信息自定义、跟踪或控制站点的使用情况。<br><br>

      我们可能会在清科慧盈网站上使用 Cookie。使用 Cookie，我们便能进行自动访问和使用我们网站的数据输入功能，此外，我们还可以通过 Cookie 跟踪我们网站的使用情况，从而确定哪些功能有用或受欢迎，哪些功能并不能帮助我们有效地改进和更新我们的服务。<br><br>

      九、 链接<br>
      我们网站上可能会提供某些链接，这些链接可能指向我们选择提供共同品牌产品的第三方或者我们认为有助于您更好的使用清科慧盈服务的其他网站。 第三方网站不受此隐私策略的约束，清科慧盈不对这些网站处理您的个人信息的相关做法进行任何说明。建议您在开始访问这些网站之前查阅其隐私策略，确定其隐私保护惯例。<br><br>

      十、 信息的访问、修改和准确性<br>
      我们采取了一些合理步骤来确保我们从您那里收到的信息正确无误且始终为最新。您有权访问您的公司/团队和个人信息， 来验证其准确性，并纠正不准确的信息。您可以使用电子邮件地址和密码通过清科慧盈网站登录您的帐户并单击账号设置”， 随时更改您的信息。您也可以通过service@hydts.cn与我们联系，访问并纠正个人信息中的错误。 如果您不想获得有关产品、服务或营销计划的信息，或者希望从任何直接营销计划、企业调查、电话营销、直邮或电子邮件列表中删除您的信息， 或者想限制我们对您的个人信息的使用或披露，请通过service@hydts.cn与我们联系。<br><br>

      十一、 业务转让<br>
      清科慧盈收集的信息将被视作一项资产。如果清科慧盈、任何附属公司、部门或部分资产被另一家公司收购，则此类信息可能成为被转让资产之一。如果情况如此，收购方公司只能按照此隐私策略（或者向您收集信息时有效的任何后续策略）使用您的信息。 请注意，转让后提交或收集的信息可能必须遵守收购方公司所采用的新隐私策略。<br><br>

      十二、 国际传输<br>
      您的信息可能被传送到或存储在您所在国家/地区、省/市/自治区或其他政府管辖区域以外的地方， 这些地方的隐私法律对您的个人信息的保护程度可能低于您所在的管辖区域。如果您位于中华人民共和国以外，您应知悉，清科慧盈可能将您的个人信息传输到中国并在中国进行处理。提交此类信息，即表示您同意此隐私策略，进而表示您同意进行此类传输。<br><br>

      我们始终致力于改善我们的业务和运营。因此，我们的策略将不断完善。 鉴于此，清科慧盈保留不时修改此隐私策略的权利。对此策略的更改将发布在www.hydts.cn网站上。 我们欢迎您不时访问我们的隐私策略页面，来查看最新隐私策略。如果我们对此隐私策略进行实质性更改， 扩展了我们使用您的公司/团队和个人信息的权利，我们将通过电子邮件或者在我们网站上的显著位置告知您并征求您的同意。<br><br>

      十三、 与我们联系<br>
      请将您对您的信息的任何访问请求、信息修改请求或有关此策略的问题发送至以下电子邮件地址：service@hydts.cn<br><br>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
/**
 *
 */
export default {
  name: 'PrivacyPage',
  data() {
    return {}
  },
  computed: {
    ...mapState({
      statusBarHeight: state => state.global.statusBarHeight
    })
  },
  methods: {
    onClickLeft () {
      window.history.go(-1)
    }
  },
  mounted() {}
}
</script>

<style lang="less">
  .privacy-page {
    .van-nav-bar {
      background-image: url("~@/assets/background.png");
      background-size: 100%;
      background-repeat: no-repeat;
      // &::after {
        // border-bottom-width: 0
      // }
      .van-nav-bar__content {
        height: 40px;
        .van-nav-bar__title {
          font-size: 19px;
          font-weight: bold;
          color: #ffffff;
          font-family: PingFang SC;
        }
        .van-nav-bar__left {
          .van-icon-arrow-left {
            color: #000000;
            font-weight: bold;
            color: #ffffff;
            &::before {
              vertical-align: middle;
            }
          }
        }
      }
    }
    .privacy-content {
      color: #666;
      line-height: 180%;
      margin: 16px;
    }
  }
</style>
